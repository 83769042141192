import app from "@/main";
import { App, ref } from "vue";

const languages = ["us", "fr", "es", "pt", "ru"];
let t: any = {};

function getDefaultLanguage(): string {
    const navigatorLanguage = navigator.language.slice(0, 2);
    let language = localStorage.getItem("language") || navigatorLanguage;
    language = languages.includes(language) ? language : "us";

    return language;
}

let currentLanguage = getDefaultLanguage();

async function changeLanguage(lang: string) {
    currentLanguage = lang;
    localStorage.setItem("language", String(lang));
    const languageTable = await import(`@/i18n/${currentLanguage}.json`);
    t = languageTable;
    app.config.globalProperties.$t = languageTable;
    app.config.globalProperties.$language.value = lang;
}

const i18nPlugin = {
    async install(app: App) {
        app.config.globalProperties.$language = ref("");
        await new Promise((r) => setTimeout(r, 500));
        const languageTable = await import(`@/i18n/${currentLanguage}.json`);
        t = languageTable;
        app.config.globalProperties.$t = JSON.parse(
            JSON.stringify(languageTable)
        );
        app.config.globalProperties.$language.value = currentLanguage;
    },
};

export { languages, currentLanguage, t, changeLanguage, i18nPlugin };
