<template>
    <component
        :is="'button'"
        :class="`btn-group f-row ${isFull ? 'btn-group--full' : ''}
         ${isSmall ? 'btn-group--small' : ''}`"
    >
        <div class="btn-group__inner__wrapper f-row-0">
            <div
                @click="onInput(option.name)"
                :class="`btn-group__inner ${
                    option.name === value ? 'btn-group__inner--active' : ''
                }`"
                v-for="option in options"
                :key="option.text"
            >
                <div class="btn-group__content f-no-wrap f-row f-center-center">
                    <i
                        :class="`mr-1 btn-group__icon fa-solid fa-${option.icon}`"
                    ></i>
                    <span class="btn-group__text">
                        {{ option.text }}
                    </span>
                </div>
            </div>
        </div>
    </component>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch } from "vue";

const props = defineProps({
    options: Array,
    variant: String,
    isFull: Boolean,
    isSmall: Boolean,
    modelValue: String,
});

const value = ref(props.modelValue);

watch(
    () => props.modelValue,
    () => {
        value.value = props.modelValue;
    }
);

const emit = defineEmits(["update:modelValue"]);
function onInput(data) {
    value.value = data;
    emit("update:modelValue", data);
}
</script>

<style scoped lang="scss">
.btn-group {
    animation: appear-bounce-up $duration / 2 var(--nth) both;
    transform-origin: center;
    background-color: $clr-dark;
    color: $clr-dark;
    cursor: pointer;
    user-select: none;
    position: relative;
    border: none;
    font-family: "Lobster", "Poppins", sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    letter-spacing: 0.05rem;
    transition: $transition;
    padding: 0.125rem 0.125rem 0.5rem 0.125rem;
    border-radius: 0.5rem;

    &--full {
        width: 100%;

        .btn-group__inner {
            flex: 1 1 0px;
        }
    }
}

.btn-group__inner__wrapper {
    border-radius: 0.4rem;
    width: 100%;
    overflow: hidden;
}
.btn-group__inner {
    background-color: $clr-gray-dark;
    transition: $transition;
    padding: 0 0 0.25rem 0;
    &:first-child {
        .btn-group__content {
            border-radius: 0.4rem;
        }
    }
    &:last-child {
        margin-right: 0;
        .btn-group__content {
            border-radius: 0.4rem;
        }
    }

    &--active {
        background-color: $clr-magenta-40;

        .btn-group__content {
            background: linear-gradient($clr-magenta-50, $clr-magenta-60);
        }
    }
}
.btn-group__inner:hover {
    margin-top: 0.25rem;
    padding: 0;
}

.btn-group__content {
    padding: 0.25rem 1rem;
    height: 1.5em;
    background-image: url("@/assets/images/abstract/btn-content.svg"),
        linear-gradient($clr-gray-light, white);
    background-repeat: no-repeat;
    background-position-x: 25%;
}

.btn-group--small {
    padding: 0.125rem 0.125rem 0.375rem 0.125rem;

    .btn-group__content {
        height: 1.5rem;
        font-size: 0.6rem;
        padding: 0.25rem 1rem;
    }
}
</style>
