import { isProd } from "./../constants/games";
import store from "../store/index";

const apiAddress = isProd ? "https://api.igrayem.com" : "http://localhost:1234";
const apiCsrfToken = null;
const apiHeaders = {
    "Content-Type": "application/json",
};

type statusCode = 200 | 400 | 404;

type apiCallbackTable = {
    200?: (data: object) => unknown;
    400?: (data: object) => unknown;
    404?: (data: object) => unknown;
};

async function get(path: string, callbackTable: apiCallbackTable) {
    if (path === store.getters.apiLoadingPath) return;

    store.commit("setApiLoadingPath", path);
    await new Promise((r) => setTimeout(r, 800));
    let response: any;

    try {
        response = await fetch(`${apiAddress}${path}`, {
            credentials: "include",
        });
    } catch (error) {
        callbackTable[404]!({});
        return;
    }

    if (callbackTable[response.status as statusCode]) {
        let data = {};
        if (response.ok) {
            data = await response.json();
        }
        callbackTable[response.status as statusCode]!(data);
    }

    store.commit("setApiLoadingPath", null);

    return response;
}

async function post(
    path: string,
    body: object,
    callbackTable: apiCallbackTable
) {
    if (path === store.getters.apiLoadingPath) return;
    store.commit("setApiLoadingPath", path);
    await new Promise((r) => setTimeout(r, 800));

    const response = await fetch(`${apiAddress}${path}`, {
        method: "POST",
        credentials: "include",
        headers: apiHeaders,
        body: JSON.stringify(body),
    });

    if (callbackTable[response.status as statusCode]) {
        let data = {};
        if (response.ok) {
            data = await response.json();
        }
        callbackTable[response.status as statusCode]!(data);
    }

    store.commit("setApiLoadingPath", null);

    return response;
}

export { apiAddress, apiCsrfToken, apiHeaders, get, post };
