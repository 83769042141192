<template>
    <div
        :class="`text-input__wrapper ${
            isFull ? 'text-input__wrapper--full' : ''
        }`"
    >
        <div
            v-if="error && error.field === name"
            class="text-input__alert__wrapper"
        >
            <div class="text-input__alert">
                <div
                    class="text-input__alert__content f-row f-start-start t-left"
                >
                    {{ error.message }}
                </div>
            </div>
        </div>
        <div
            :class="`text-input 
        ${isSmall ? 'text-input--small' : ''}
        ${
            value === ''
                ? ''
                : error && error.field === name
                ? 'text-input--invalid'
                : 'text-input--valid'
        }`"
        >
            <label v-if="icon" class="text-input__icon"
                ><i :class="`fa-solid fa-${icon}`"></i
            ></label>
            <input
                :disabled="isDisabled"
                @input="onInput(value)"
                :type="type ? (isPasswordShown ? 'text' : type) : 'text'"
                :placeholder="placeholder"
                class="text-input__input"
                v-model="value"
                v-focus="autoFocus"
            />
            <div v-if="type === 'password'" class="text-input__eye">
                <i
                    @click="toggleIsPasswordShown()"
                    :class="`fa-solid fa-eye${isPasswordShown ? '-slash' : ''}`"
                ></i>
            </div>
        </div>
    </div>
</template>

<script setup>
import { watch, defineProps, defineEmits, ref } from "vue";

const value = ref("");

const isPasswordShown = ref(false);
const toggleIsPasswordShown = () =>
    (isPasswordShown.value = !isPasswordShown.value);

const props = defineProps({
    error: Object,
    name: String,
    modelValue: String,
    icon: String,
    placeholder: String,
    autoFocus: Boolean,
    isSmall: Boolean,
    isFull: Boolean,
    isDisabled: Boolean,
    type: {
        type: String,
        default: "text",
    },
});

watch(
    () => props.modelValue,
    () => {
        value.value = props.modelValue;
    }
);

const emit = defineEmits(["update:modelValue"]);
function onInput(value) {
    emit("update:modelValue", value);
}
</script>

<style lang="scss">
.text-input__wrapper {
    animation: appear-bounce-up $duration / 2 var(--nth) both;

    padding: 0.125rem 0.125rem 0.5rem 0.125rem;
    background: $clr-dark;
    border-radius: 0.5rem;
    position: relative;

    &--full {
        width: 100%;
    }
}

.text-input {
    color: clr-neuter(80);
    display: flex;
    transition: $transition;
    border-radius: 0.4rem;
    overflow: hidden;

    &__input {
        background: linear-gradient($clr-gray-light, white);
        width: 100%;
        padding: 0.3rem 0.6rem;
        font-size: 0.6rem;
        letter-spacing: 0.05rem;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
        flex-grow: 1;
        border: none;
        outline: none;
        &::placeholder {
            letter-spacing: 0;
            color: $clr-placeholder;
        }
        &[type="password"] {
            letter-spacing: 0.1rem;
        }
    }
}

.text-input__alert__wrapper {
    transform: translateY(-50%);
    z-index: 1;
    position: absolute;
    top: 50%;
    left: calc(100% + 0.5rem);
}

.text-input__alert {
    animation: appear-zoom $duration / 2;
    @extend %common-wrapper;
    width: 10rem;

    &__content {
        background: linear-gradient($clr-gray-light, white);
        border-radius: 0.4rem;
        padding: 0.5rem;
    }
}

.text-input__eye {
    padding: 0.5rem 0.75rem;
    background: linear-gradient($clr-gray-light, white);
    color: $clr-dark;
    cursor: pointer;
}

.text-input__icon {
    color: white;
    padding: 0.5rem 0.75rem;
    background-color: $clr-dark-light;
    transition: $transition;
}

.text-input--invalid .text-input__icon {
    color: clr-magenta(70);
}

.text-input--valid .text-input__icon {
    color: clr-cyan(50);
}
</style>
