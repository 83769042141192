<template>
    <component
        :is="to ? 'router-link' : 'button'"
        :class="`btn ${isFull ? 'btn--full' : ''} ${text ? '' : 'btn--icon'} ${
            isSmall ? 'btn--small' : ''
        }`"
        :to="to"
    >
        <div class="btn__inner">
            <div class="btn__content f-row f-center-center">
                <SpinnerIcon v-if="isLoading || loading === apiLoadingPath" />
                <i
                    :class="`${
                        text ? 'mr-1' : ''
                    } btn__icon fa-solid fa-${icon}`"
                ></i>
                <span v-if="text" class="btn__text">
                    {{ text }}
                </span>
            </div>
        </div>
    </component>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const apiLoadingPath = computed(() => store.getters.apiLoadingPath);

defineProps({
    text: String,
    icon: String,
    variant: String,
    isFull: Boolean,
    isSmall: Boolean,
    to: String,
    isLoading: Boolean,
    loading: String,
});
</script>

<style scoped lang="scss">
.btn {
    animation: appear-bounce-up $duration / 2 var(--nth) both;
    transform-origin: center;
    background-color: $clr-dark;
    color: $clr-dark;
    cursor: pointer;
    user-select: none;
    padding: 0.125rem 0.125rem 0.5rem 0.125rem;
    position: relative;
    border: none;
    font-family: "Lobster", "Poppins", sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    border-radius: 0.5rem;
    letter-spacing: 0.05rem;
    transition: $transition;
}
.btn:hover {
    margin-top: 0.25rem;
}

.btn[disabled] {
    opacity: 0.5;
}

.btn__inner {
    background-color: $clr-gray-dark;
    border-radius: 0.4rem;
    padding: 0 0 0.25rem 0;
    transition: $transition;
}
.btn:hover .btn__inner {
    padding: 0;
}

.btn__content {
    padding: 0.25rem 1rem;
    height: 1.5em;
    border-radius: 0.4rem;
    background-image: url("@/assets/images/abstract/btn-content.svg"),
        linear-gradient($clr-gray-light, white);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 25%;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.btn__text {
    text-overflow: ellipsis;
}

.btn--full {
    width: 100%;
}

.btn--small {
    padding: 0.125rem 0.125rem 0.375rem 0.125rem;
    border-radius: 0.3rem;

    .btn__content {
        height: 1.5rem;
        font-size: 0.6rem;
        padding: 0.25rem 1rem;
        border-radius: 0.2rem;
    }

    .btn__inner {
        border-radius: 0.2rem;
    }
}

.btn--icon .btn__content {
    background-size: cover;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2.5rem;
}

.btn--icon.btn--small .btn__content {
    width: 1.75rem;
    height: 1.5rem;
}
</style>
