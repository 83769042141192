import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store/index";

import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: HomeView,
    },
    {
        path: "/debug",
        name: "Debug",
        component: () => import("../games/PingPongSquareMain.vue"),
    },
    {
        path: "/shop",
        name: "Shop",
        component: () => import("../views/ShopView.vue"),
    },
    {
        path: "/play",
        name: "Play",
        component: () => import("../views/PlayView.vue"),
    },
    {
        path: "/i/:id",
        name: "Invited to play",
        component: () => import("../views/InviteView.vue"),
    },
    // {
    //     path: "/debug",
    //     name: "Debug",
    //     component: () => import("../games/common/DebugView.vue"),
    // },
    {
        path: "/about",
        name: "About",
        component: () => import("../views/AboutView.vue"),
    },
    {
        path: "/terms-of-service",
        name: "Terms of service",
        component: () => import("../views/TermsOfServiceView.vue"),
    },
    {
        path: "/terms-of-sale",
        name: "Terms of sale",
        component: () => import("../views/TermsOfSaleView.vue"),
    },
    {
        path: "/sign-in",
        name: "Sign in",
        component: () => import("../views/SignInView.vue"),
    },
    {
        path: "/sign-up",
        name: "Sign up",
        component: () => import("../views/SignUpView.vue"),
    },
    {
        path: "/create-room",
        name: "Create room",
        component: () => import("../views/CreateRoomView.vue"),
    },
    {
        path: "/join-room",
        name: "Join room",
        component: () => import("../views/JoinRoomView.vue"),
    },
    {
        path: "/all-games",
        name: "All games",
        component: () => import("../views/AllGamesView.vue"),
    },
    {
        path: "/game/:name",
        name: "Game",
        component: () => import("../views/GameView.vue"),
    },
    {
        path: "/email-sent",
        name: "Email sent",
        component: () => import("../views/EmailSentView.vue"),
    },
    {
        path: "/verify-email/:token",
        name: "Verify email",
        component: () => import("../views/VerifyEmailView.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const isLoggedIn = store.getters.isSignedIn;
    if (!isLoggedIn && to.fullPath === "/shop") {
        next("/sign-in");
        return;
    }
    next();
});

router.beforeEach((to, from, next) => {
    store.commit("setIsRouteLoading", true);
    next();
});
router.afterEach((to, from) => {
    store.commit("setIsRouteLoading", false);
});

export default router;
