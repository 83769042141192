<template>
    <HomeOverlay>
        <HomeContainer>
            <div class="h-100 f f-center-center">
                <img
                    class="home-logo mb-6"
                    alt="logo"
                    src="@/assets/images/logo/logo-home.svg"
                />
                <p class="mb-8">
                    <span class="t-body t-bold mr-1">
                        {{ $t.play.wannaPlay.title }}
                    </span>
                    <span>
                        {{ $t.play.wannaPlay.caption }}
                    </span>
                </p>
                <div class="f-2 f-md-row-2 f-center-center">
                    <SimpleButton
                        to="/create-room"
                        style="--nth: 0.1s"
                        icon="caret-right"
                        :text="$t.play.wannaPlay.createRoom"
                    />
                    <span>{{ $t.common.or }}</span>
                    <SimpleButton
                        to="/join-room"
                        style="--nth: 0.2s"
                        icon="caret-right"
                        :text="$t.play.wannaPlay.joinRoom"
                    />
                </div>
            </div>
        </HomeContainer>
    </HomeOverlay>
</template>
