<template>
    <div class="home-container">
        <slot></slot>
    </div>
</template>

<style lang="scss">
.home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: calc(45vw);
    max-width: calc(100vw - 1rem);
    margin: auto;
    height: 100%;

    @include bp("sm") {
        width: 600px;
    }
}
</style>
