import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

const app = createApp(App);

//
// Components
//

import SpinnerIcon from "@/elements/SpinnerIcon.vue";
import SimpleButton from "@/elements/SimpleButton.vue";
import SimpleButtonGroup from "@/elements/SimpleButtonGroup.vue";
import BasicButton from "@/elements/BasicButton.vue";
import CustomButton from "@/elements/CustomButton.vue";
import TextInput from "@/elements/TextInput.vue";
import SmallSpacer from "@/elements/SmallSpacer.vue";
import BasicLink from "@/elements/BasicLink.vue";
import SimpleAvatar from "@/elements/SimpleAvatar.vue";
import RoundIcon from "@/elements/RoundIcon.vue";

app.component("SpinnerIcon", SpinnerIcon);
app.component("SimpleButton", SimpleButton);
app.component("SimpleButtonGroup", SimpleButtonGroup);
app.component("BasicButton", BasicButton);
app.component("CustomButton", CustomButton);
app.component("TextInput", TextInput);
app.component("SmallSpacer", SmallSpacer);
app.component("BasicLink", BasicLink);
app.component("SimpleAvatar", SimpleAvatar);
app.component("RoundIcon", RoundIcon);

import SettingsIcons from "@/components/SettingsIcons.vue";
import HomeOverlay from "@/components/HomeOverlay.vue";
import HomeOverlayAccount from "@/components/HomeOverlayAccount.vue";
import LoadingBlock from "@/components/LoadingBlock.vue";
import GameOverlayDefault from "@/components/GameOverlayDefault.vue";
import RouteLoading from "@/components/RouteLoading.vue";
import WannaPlay from "@/components/WannaPlay.vue";
import PlayLobby from "@/components/PlayLobby.vue";
import ToastList from "@/components/ToastList.vue";
import GameCard from "@/components/GameCard.vue";
import VisibilityWrapper from "@/components/VisibilityWrapper.vue";

app.component("SettingsIcons", SettingsIcons);
app.component("HomeOverlay", HomeOverlay);
app.component("HomeOverlayAccount", HomeOverlayAccount);
app.component("LoadingBlock", LoadingBlock);
app.component("GameOverlayDefault", GameOverlayDefault);
app.component("RouteLoading", RouteLoading);
app.component("WannaPlay", WannaPlay);
app.component("WannaPlay", WannaPlay);
app.component("PlayLobby", PlayLobby);
app.component("ToastList", ToastList);
app.component("GameCard", GameCard);
app.component("VisibilityWrapper", VisibilityWrapper);

import HomeContainer from "@/layout/HomeContainer.vue";
import AboutContainer from "@/layout/AboutContainer.vue";
import ButtonContainer from "@/layout/ButtonContainer.vue";
import AuthLayout from "@/layout/AuthLayout.vue";

app.component("HomeContainer", HomeContainer);
app.component("AboutContainer", AboutContainer);
app.component("ButtonContainer", ButtonContainer);
app.component("AuthLayout", AuthLayout);

// Plugins

import { i18nPlugin } from "./helpers/i18n";
app.use(i18nPlugin);

// Directives

import { autoFocusDirective } from "@/directives/auto-focus";

app.directive("focus", autoFocusDirective);

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $t: any;
        $language: any;
    }
}

app.use(store).use(router).mount("#app");

export default app;
