import { DirectiveBinding } from "vue";
type FocusableElement = HTMLInputElement | HTMLTextAreaElement;

const autoFocusDirective = {
    mounted: (el: FocusableElement, binding: DirectiveBinding) => {
        if (binding.value) {
            el.focus();
        }
    },
};

export { autoFocusDirective };
