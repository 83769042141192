export const GAMES = {
    Poker: {
        name: "Poker",
        src: "poker",
    },
    "Classic Blackjack": {
        name: "Classic Blackjack",
        src: "classic-blackjack",
    },
    // "Unfair Blackjack": {
    //     name: "Unfair Blackjack",
    //     src: "unfair-blackjack",
    // },
    Calamite: {
        name: "Calamité",
        src: "calamite",
    },
    "Drawing battle": {
        name: "Drawing battle",
        src: "drawing-battle",
    },
    "Novice Artists": {
        name: "Novice Artists",
        src: "novice-artists",
    },
    // "Ping Pong Square": {
    //     name: "Ping Pong Square",
    //     src: "ping-pong-square",
    // },
    // "Sorting Algo": {
    //     name: "Sorting Algo",
    //     src: "sorting-algo",
    // },
};
