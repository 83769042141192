<template>
    <hr class="small-spacer" />
</template>

<style lang="scss">
.small-spacer {
    animation: appear-bounce-up $duration / 2;
    border: none;
    height: 0.25rem;
    width: 1rem;
    border-radius: 1rem;
    background: $clr-dark;
}
</style>
