import { createStore } from "vuex";
import { v4 as uuidv4 } from "uuid";

type User = { username: string; avatar: string; state: any };

interface RoomData {
    username: string;
    owner: string;
    players: { [key: string]: User };
    playerCount: number;
    gamePlayers: { [key: string]: any };
    isPlaying: boolean;
    currentGame: number;
    maxPlayerCount: number;
}

interface Toast {
    type: "error" | "warning" | "info" | "success";
    message: string;
}

export default createStore({
    state: {
        apiLoadingPath: "",
        isSignedIn: false,
        user: {},
        isRouteLoading: false,
        room: {} as RoomData,
        toasts: <Toast[]>[],
    },
    getters: {
        toasts(state) {
            return state.toasts;
        },
        room(state) {
            return state.room;
        },
        isRouteLoading(state) {
            return state.isRouteLoading;
        },
        apiLoadingPath(state) {
            return state.apiLoadingPath;
        },
        isSignedIn(state) {
            return state.isSignedIn;
        },
        user(state) {
            return state.user;
        },
    },
    mutations: {
        willLaunchGame(state) {
            state.room.gamePlayers = state.room.players;
            for (const player in state.room.players) {
                state.room.players[player].state = {};
            }
        },
        joinedRoom(state, data: RoomData) {
            state.room = data;
            for (const player in state.room.players) {
                state.room.players[player].state = {};
            }
        },
        playerJoined(state, data: User) {
            state.room.players[data.username] = data;
            state.room.players[data.username].state = {};
        },
        playerLeft(state, username) {
            delete state.room.players[username];
        },
        setCurrentGame(state, id) {
            state.room.currentGame = id;
        },
        setIsRouteLoading(state, data: boolean) {
            state.isRouteLoading = data;
        },
        signIn(state, data: any) {
            state.isSignedIn = true;
            state.user = data.user;
        },
        logOut(state) {
            state.isSignedIn = false;
            state.user = {};
        },
        setApiLoadingPath(state, path: string) {
            state.apiLoadingPath = path;
        },
        addToast(state, toast) {
            toast["uuid"] = uuidv4();
            state.toasts.push(toast);
        },
        popToast(state) {
            state.toasts.shift();
        },
    },
    actions: {
        async addToast(ctx, toast) {
            ctx.commit("addToast", toast);
            setTimeout(() => ctx.commit("popToast"), 3000);
        },
    },
    modules: {},
});
