<template>
    <HomeOverlay>
        <HomeContainer>
            <div class="h-100 f f-center-center">
                <div class="home-logo__wrapper mb-12">
                    <img
                        class="home-logo"
                        alt="logo"
                        src="@/assets/images/logo/logo-home.svg"
                    />
                </div>
                <div class="mb-8">
                    <ButtonContainer>
                        <div class="f-1">
                            <SimpleButton
                                to="/create-room"
                                style="--nth: 0s"
                                icon="caret-right"
                                isFull
                                :text="$t.home.createRoom"
                            />
                            <SimpleButton
                                disabled
                                to="/join-room"
                                style="--nth: 0.1s"
                                icon="caret-right"
                                isFull
                                :text="$t.home.joinRoom"
                            />
                            <SimpleButton
                                to="/all-games"
                                style="--nth: 0.2s"
                                icon="gamepad"
                                isFull
                                :text="$t.home.allGames"
                            />
                        </div>
                    </ButtonContainer>
                </div>
            </div>
        </HomeContainer>
    </HomeOverlay>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.home-logo__wrapper {
    z-index: 1;
    width: 130%;
    max-width: calc(100vw - 2rem);
    max-height: 30vh;
}

.home-logo {
    max-height: 30vh;
    animation: appear-zoom $duration / 2;
}
</style>
