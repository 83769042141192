import { io, Socket } from "socket.io-client";
import { isProd } from "../constants/games";
import store from "@/store/index";
import router from "@/router/index";
import { playSound } from "./sound";

const wsAddress = isProd
    ? "wss://ws.igrayem.com"
    : `ws://${window.location.hostname}:3000`;

interface HandlersMap {
    connected: (data: any) => void;
    connectError: () => void;
}

class WS {
    hasSocket: boolean;
    state: number;
    socket: Socket | null;
    url: string;

    constructor(url: string) {
        this.hasSocket = false;
        this.state = 0;
        this.socket = null;
        this.url = url;
    }

    async join(token: string, handlersMap: HandlersMap) {
        if (this.hasSocket) return;

        this.hasSocket = true;
        this.socket = await io(this.url, {
            query: {
                token,
            },
            secure: true,
        });
        this.socket.once("connected", handlersMap.connected);
        this.socket.once("connect_error", handlersMap.connectError);

        this.socket.once("disconnect", this.handleDisconnect);
        this.socket.on("playerJoined", this.handlePlayerJoined);
        this.socket.on("playerLeft", this.handlePlayerLeft);
        this.socket.on("changeCurrentGame", this.handleChangeCurrentGame);

        if (!isProd) return;

        window.onbeforeunload = function () {
            return "Are you sure you want to close the window ?";
        };
    }

    handle(event: string, callback: (props: any) => void) {
        if (!this.socket) return;
        this.socket.on(event, callback);
    }

    handleDisconnect() {
        playSound("disconnect");
        this.hasSocket = false;
        router.push("/");
    }

    handlePlayerJoined(data: any) {
        playSound("player-joined");
        store.commit("playerJoined", data);
    }

    handlePlayerLeft(data: any) {
        playSound("player-left");
        store.commit("playerLeft", data);
    }
    handleChangeCurrentGame(data: any) {
        store.commit("setCurrentGame", data);
    }

    sendChat(message: string) {
        this.socket!.emit("chat", message);
    }

    changeCurrentGame(id: number) {
        this.socket!.emit("changeCurrentGame", id);
    }

    update(data: any) {
        this.socket!.emit("update", data);
    }

    removeGameListeners() {
        this.socket!.off("update");
        this.socket!.off("endGame");
    }

    kick(username: string) {
        this.socket!.emit("kick", { username });
    }

    play(props: any) {
        this.socket!.emit("play", props);
    }

    lobby() {
        this.socket!.off("update");
        this.socket!.off("endGame");
    }

    leave() {
        if (this.hasSocket) {
            this.socket!.disconnect();
            this.hasSocket = false;
            window.onbeforeunload = null;
        }
    }
}

export const ws = new WS(wsAddress);
