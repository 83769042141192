const soundProps = {
    soundVolume: Number(localStorage.getItem("soundVolume") ?? 1),
    musicVolume: Number(localStorage.getItem("musicVolume") ?? 1),
    currentMusic: new Audio(),
};

async function playSound(sound: string, delay?: number) {
    if (delay) await new Promise((r) => setTimeout(r, delay));

    const audio = new Audio(`/assets/audio/sound/${sound}.aac`);
    audio.volume = soundProps.soundVolume;
    audio.play();
}

async function playMusic(music: string, delay?: number) {
    if (delay) await new Promise((r) => setTimeout(r, delay));

    soundProps.currentMusic.pause();
    soundProps.currentMusic = new Audio(`/assets/audio/music/${music}.aac`);
    soundProps.currentMusic.loop = true;
    soundProps.currentMusic.volume = soundProps.musicVolume;
    soundProps.currentMusic.play();
}

async function changeSoundVolume(value: number) {
    soundProps.soundVolume = value;

    localStorage.setItem("soundVolume", String(value));
}

async function changeMusicVolume(value: number) {
    soundProps.musicVolume = value;
    soundProps.currentMusic.volume = value;

    localStorage.setItem("musicVolume", String(value));
}

export {
    soundProps,
    playSound,
    playMusic,
    changeSoundVolume,
    changeMusicVolume,
};
