<template>
    <div class="about-container">
        <div class="about-container__content">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss">
.about-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: calc(55vw);
    max-width: calc(100vw - 1rem);
    margin: auto;
    height: 100%;
    padding: 3.25rem 0;
    @include bp("sm") {
        width: 500px;
        padding: 0;
    }
}

.about-container__content {
    height: calc(100vh - 1rem);
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
</style>
