<template>
    <div class="button-container">
        <slot></slot>
    </div>
</template>

<style lang="scss">
.button-container {
    margin: 0 auto;
    position: relative;
    min-width: calc(100vw - 1rem);
    @include bp("sm") {
        min-width: calc(25vw);
        max-width: calc(100vw - 1rem);
        width: 450px;
    }
}

@include bp("sm") {
    .button-container::before,
    .button-container::after {
        height: 5rem;
        width: 5rem;
        content: "";
        position: absolute;
        background-repeat: no-repeat;
    }

    .button-container::before {
        animation: appear-bounce-left $duration / 2;
        top: -2.7rem;
        left: -3.25rem;
        background-image: url("@/assets/images/abstract/button-container-top.svg");
    }

    .button-container::after {
        animation: appear-bounce-right $duration / 2;
        bottom: -3rem;
        right: -3.65rem;
        background-image: url("@/assets/images/abstract/button-container-bottom.svg");
    }
}
</style>
