<template>
    <svg class="simple-avatar" viewBox="0 0 512 512">
        <defs>
            <linearGradient
                id="avatar"
                x1="256"
                y1="41.41"
                x2="256"
                y2="474.93"
                gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stop-color="#ffbe4a" />
                <stop offset="1" stop-color="#ed8d37" />
            </linearGradient>
        </defs>
        <rect class="f" width="512" height="512" rx="36.33" ry="36.33" />
        <rect
            class="e"
            x="42.29"
            y="42.29"
            width="427.42"
            height="427.42"
            rx="36.33"
            ry="36.33"
        />
        <path
            class="f"
            d="M182.8,334.74c-3.25-2.93-.17-8.2,3.97-6.8,20.21,6.86,45.79,12.43,74.89,11.4,25-.89,46.35-6.44,63.41-12.97,3.92-1.5,7.24,3.26,4.45,6.4-11.64,13.13-30.71,29.43-57.18,33.96-38.91,6.65-71.7-15.93-89.55-31.99Z"
        />
        <ellipse class="f" cx="180.35" cy="214.34" rx="26.1" ry="38.89" />
        <ellipse class="f" cx="324.29" cy="214.34" rx="26.1" ry="38.89" />
    </svg>
</template>

<style scoped lang="scss">
.e {
    fill: url(#avatar);
}
.f {
    fill: #1e2449;
}
</style>
