<template>
    <TransitionGroup class="container" name="toasts" tag="div">
        <div
            v-for="toast in toasts"
            :key="toast.uuid"
            :class="`t-small wrapper toast--${toast.type}`"
        >
            <div class="content">
                <i :class="`icon mr-1 fa-solid fa-${icons[toast.type]}`"></i>
                {{ toast.message }}
            </div>
        </div>
    </TransitionGroup>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();

const icons = {
    success: "check",
    warning: "exclamation",
    error: "xmark",
    info: "info",
};

const toasts = computed(() => store.getters.toasts);
</script>

<style scoped lang="scss">
.container {
    pointer-events: none;
    z-index: 10;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    bottom: 1rem;
    transform: translateX(-50%);
    width: 500px;
    max-width: calc(100vw - 2rem);
}

.wrapper {
    @extend %common-wrapper;
    margin-top: 0.25rem;
    overflow: hidden;
    transition: $transition;
}

.content {
    @extend %common-content;
    padding: 0.25rem;
    transition: $transition;
}

.toast--success .icon {
    color: $clr-success;
}
.toast--warning .icon {
    color: $clr-warning;
}
.toast--info .icon {
    color: $clr-info;
}
.toast--error .icon {
    color: $clr-error;
}

.toasts-enter-from,
.toasts-leave-to {
    opacity: 0;
    margin-top: 0;
}
</style>
