<template>
    <component
        :is="to ? 'router-link' : 'button'"
        :to="to"
        :class="{
            'basic-button': true,
            'basic-button--full': isFull,
            'basic-button--icon': !text,
            'basic-button--large': isLarge,
            'basic-button--small': isSmall,
            'basic-button--dark': variant === 'dark',
        }"
    >
        <div class="basic-button__inner">
            <div class="basic-button__content">
                <SpinnerIcon v-if="isLoading || loading === apiLoadingPath" />
                <i
                    :class="`${
                        text ? 'mr-1' : ''
                    } basic-button__icon fa-solid fa-${icon}`"
                ></i>
                <span v-if="text" class="basic-button__text">
                    {{ text }}
                </span>
            </div>
        </div>
    </component>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const apiLoadingPath = computed(() => store.getters.apiLoadingPath);

defineProps({
    text: String,
    icon: String,
    variant: String,
    isFull: Boolean,
    isLarge: Boolean,
    isSmall: Boolean,
    to: String,
    isLoading: Boolean,
    loading: String,
});
</script>

<style lang="scss">
.basic-button {
    background: none;
    color: $clr-dark;
    cursor: pointer;
    user-select: none;
    padding: 0;
    position: relative;
    border: none;
    font-family: "Lobster", "Poppins", sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    letter-spacing: 0.05rem;
    transition: $transition;
}
.basic-button:hover .basic-button__inner {
    margin-top: 0.25rem;
}

.basic-button__inner {
    background-color: $clr-gray-dark;
    border-radius: 0.4rem;
    padding: 0 0 0.25rem 0;
    transition: $transition;
}
.basic-button:hover .basic-button__inner {
    padding: 0;
}

.basic-button__content {
    padding: 0.25rem 1rem;
    border-radius: 0.4rem;
    background-image: linear-gradient($clr-gray-light, white);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 25%;
}

.basic-button--icon .basic-button__content {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2.5rem;
}

.basic-button--icon.basic-button--small .basic-button__content {
    height: 1.5rem;
    width: 1.75rem;
}

.basic-button--full {
    width: 100%;
}

.basic-button--big {
    font-size: 20px;
    padding: 1rem 2rem;
}

.basic-button--small {
    font-size: 0.6rem;

    .basic-button__inner {
        border-radius: 0.2rem;
    }

    .basic-button__content {
        border-radius: 0.2rem;
    }
}

.basic-button__spinner ~ .basic-button__text,
.basic-button__spinner ~ .basic-button__icon {
    visibility: hidden;
}

.basic-button--dark {
    .basic-button__inner {
        background-color: $clr-dark;
    }

    .basic-button__content {
        background: $clr-dark-light;
        color: white;
    }
}
</style>
